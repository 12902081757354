import { useCallback, useState } from 'react';

export default function useTriggerUpdate() {
  const [trigger, setTrigger] = useState({});

  const triggerUpdate = useCallback(() => {
    setTrigger({});
  }, []);

  return [trigger, triggerUpdate] as const;
}
