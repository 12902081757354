import { makeStyles, createStyles, TableRow, TableCell, Grid, InputBase, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import PrivilegeDto from '../model/DTOs/PrivilegeDto';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      boxSizing: 'border-box',
      borderLeft: '5px solid transparent',
      transition: 'border-left 0.1s ease-in-out',
    },
    selectedRow: {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
    inputGridItem: {
      flexGrow: 1,
    },
    inputField: {
      width: '100%',
    },
    error: {
      color: theme.palette.error.main as string,
    },
  })
);

interface IManagePrivilegeRowProps {
  privilege: PrivilegeDto;
  onUpdate?: (name: string, uuid: string) => boolean;
  onEditButton?: () => void;
  onDoneButton?: () => void;
  removeFromTemporaryPrivileges?: () => void;
}

const ManagePrivilegeRow: React.FunctionComponent<IManagePrivilegeRowProps> = (props) => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  let { privilege, onEditButton, onUpdate, onDoneButton, removeFromTemporaryPrivileges } = props;

  const [selected, setSelected] = useState(!privilege.uuid);
  const [privilegeName, setPrivilegeName] = useState(privilege.name);
  const [error, setError] = useState(false);

  const queryClient = useQueryClient();

  const addPrivilege = useMutation(
    (newPrivilege: PrivilegeDto) =>
      createApiClient(context, dispatchContext).post(`${context.config?.MAS_URL}/api/v1/privileges`, {
        json: newPrivilege,
      }),
    { onSuccess: () => queryClient.invalidateQueries('privileges') }
  );

  const updatePrivilege = useMutation(
    (privilegeToUpdate: PrivilegeDto) =>
      createApiClient(context, dispatchContext).patch(`${context.config?.MAS_URL}/api/v1/privileges/${privilegeToUpdate.uuid}`, {
        json: privilegeToUpdate,
      }),
    { onSuccess: () => queryClient.invalidateQueries('privileges') }
  );

  const deletePrivilege = useMutation(
    (privilegeId: string) => createApiClient(context, dispatchContext).delete(`${context.config?.MAS_URL}/api/v1/privileges/${privilegeId}`),
    { onSuccess: () => queryClient.invalidateQueries('privileges') }
  );

  const handleSave = () => {
    if (error) {
      return;
    }

    if (privilege.uuid) {
      updatePrivilege.mutate({ ...privilege, name: privilegeName });
    } else {
      addPrivilege.mutate({ ...privilege, name: privilegeName });
      removeFromTemporaryPrivileges?.();
    }
    setSelected(false);
    onDoneButton?.();
  };

  return (
    <TableRow key={privilege.uuid}>
      <TableCell className={clsx(selected && classes.selectedRow, classes.row)} align="left">
        <Grid container alignItems="center" justify="space-between">
          <Grid item className={classes.inputGridItem}>
            <InputBase
              className={clsx(error && classes.error, classes.inputField)}
              value={privilegeName}
              readOnly={!selected}
              onChange={(event) => {
                let name = event.target.value as string;
                setPrivilegeName(name);
                setError(!(onUpdate?.(name, privilege.uuid || privilege.localUuid) ?? true));
              }}
              onKeyPress={(event) => {
                if (event.code === 'Enter') {
                  handleSave();
                }
              }}
              inputRef={(input) => input && selected && input.focus()}
            />
          </Grid>
          <Grid item>
            {!selected ? (
              <IconButton
                size="small"
                onClick={() => {
                  setSelected(true);
                  onEditButton?.();
                }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={handleSave} disabled={error}>
                <DoneIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">{privilege.uuid || '<Will be generated automatically>'}</TableCell>
      <TableCell align="right">
        <IconButton
          size="small"
          onClick={() => {
            if (privilege.uuid) {
              deletePrivilege.mutate(privilege.uuid);
            }
            removeFromTemporaryPrivileges?.();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ManagePrivilegeRow;
