import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import PrivilegeDto from '../model/DTOs/PrivilegeDto';
import useTriggerUpdate from '../hooks/useTriggerUpdate';
import { useQuery } from 'react-query';
import ManagePrivilegeRow from '../components/ManagePrivilegeRow';
import Utils from "../utils/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    row: {
      borderLeft: '5px solid transparent',
      transition: 'border-left 0.1s ease-in-out',
    },
    selectedRow: {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
  })
);

interface IManagePrivilegesDialogProps {
  open: boolean;
  onClose: () => void;
}

const ManagePrivilegesDialog: React.FunctionComponent<IManagePrivilegesDialogProps> = (props) => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  const { open, onClose } = props;

  const [editingPrivilege, setEditingPrivilege] = useState(false);

  const [localAvailablePrivileges, setLocalAvailablePrivileges] = useState<PrivilegeDto[]>([]);
  const [temporaryPrivileges, setTemporaryPrivileges] = useState<PrivilegeDto[]>([]);

  const [, triggerComponentUpdate] = useTriggerUpdate();

  // Fetch the available privileges
  const availablePrivileges = useQuery(
    ['privileges'],
    () => createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/privileges`, { searchParams: { size: 2000 } }).json<PrivilegeDto[]>(),
    {
      onSuccess: (data) => setLocalAvailablePrivileges(data),
    }
  );

  // Reset the dialog on open
  useEffect(() => {
    setEditingPrivilege(false);
    setTemporaryPrivileges([]);
  }, [open]);

  // Updates the local privilege information
  const onPrivilegeUpdate = (name: string, uuid: string) => {
    setTemporaryPrivileges((oldPrivileges) => {
      oldPrivileges.forEach((privilege) => {
        if (privilege.localUuid === uuid) {
          privilege.name = name;
        }
      });

      return oldPrivileges;
    });

    setLocalAvailablePrivileges((oldPrivileges) => {
      oldPrivileges.forEach((privilege) => {
        if (privilege.uuid === uuid) {
          privilege.name = name;
        }
      });

      return oldPrivileges;
    });

    return [...localAvailablePrivileges, ...temporaryPrivileges].find((t) => t.name === name && (t.uuid || t.localUuid) !== uuid) === undefined;
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Privileges</DialogTitle>
      <DialogContent>
        <Grid container justify="flex-end">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={!availablePrivileges.isSuccess || editingPrivilege}
            onClick={() => {
              // Set the editing state to avoid multiple additions at once
              setEditingPrivilege(true);

              let tmpPrivs = temporaryPrivileges;
              tmpPrivs.push(new PrivilegeDto());
              setTemporaryPrivileges(tmpPrivs);

              // Trigger UI update
              triggerComponentUpdate();
            }}
          >
            Add Privilege
          </Button>
        </Grid>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">UUID</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {localAvailablePrivileges?.sort((a, b) => Utils.sortAlphabetically(a.name, b.name)).map((privilege, index) => (
                <ManagePrivilegeRow
                  key={privilege.uuid || privilege.localUuid}
                  privilege={privilege}
                  onUpdate={onPrivilegeUpdate}
                  onEditButton={() => setEditingPrivilege(true)}
                  onDoneButton={() => setEditingPrivilege(false)}
                />
              ))}
              {temporaryPrivileges.sort((a, b) => Utils.sortAlphabetically(a.name, b.name)).map((privilege, index) => (
                <ManagePrivilegeRow
                  key={privilege.uuid || privilege.localUuid}
                  privilege={privilege}
                  onUpdate={onPrivilegeUpdate}
                  onEditButton={() => setEditingPrivilege(true)}
                  onDoneButton={() => setEditingPrivilege(false)}
                  removeFromTemporaryPrivileges={() => {
                    setTemporaryPrivileges((oldPrivileges) => {
                      oldPrivileges.splice(index, 1);
                      return oldPrivileges;
                    });
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagePrivilegesDialog;
