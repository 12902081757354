import { useState, useEffect } from 'react';

/**
 * Hook to listen on a certian key press
 * Taken from: https://usehooks.com/useKeyPress/
 *
 * @param {String} targetKey
 */
export default function useKeyPress(targetKey: string, callback?: () => void, criterion?: boolean) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler(event: KeyboardEvent) {
      if (event.key === targetKey) {
        setKeyPressed(true);

        if (callback !== undefined) {
          // Additionally checks for a specified criterion
          if (criterion === undefined) {
            callback();
          } else {
            if (criterion) {
              callback();
            }
          }
        }
      }
    }

    // If released key is our target key then set to false
    const upHandler = (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        setKeyPressed(false);
      }
    };

    // Register the event handlers
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [callback, targetKey, criterion]);

  return keyPressed;
}
