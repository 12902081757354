import React, { useContext, useState } from 'react';
import { Container, CssBaseline, makeStyles, createStyles, TextField, Typography, Paper } from '@material-ui/core';
import AccessTokenResponse from '../model/AccessTokenResponse';
import ky from 'ky';
import { useHistory } from 'react-router-dom';
import ProgressButton from '../components/ProgressButton';
import { Context } from '../context/ContextStore';
import { IActionType } from '../context/ContextInterfaces';
import useKeyPress from '../hooks/useKeyPressed';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    loginButton: {
      margin: theme.spacing(3, 0, 2),
    },
    hidden: {
      display: 'none',
    },
    error: {
      marginTop: '1em',
      marginBottom: '1em',
      width: '100%',
    },
  })
);

const Login: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const [context, dispatchContext] = useContext(Context);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [processRunning, setProcessRunning] = useState(false);

  useKeyPress('Enter', handleLoginLogout, !context.authenticated);

  function handleLoginLogout() {
    if (context.authenticated) {
      // Remove stored tokens
      dispatchContext({ type: IActionType.SET_AUTHENTICATED, payload: false });
      dispatchContext({ type: IActionType.REMOVE_ACCESSTOKEN });
      dispatchContext({ type: IActionType.REMOVE_REFRESHTOKEN });
    } else {
      setProcessRunning(true);
      // Login
      ky.post(
        `${context.config?.MAS_URL}/oauth/token?grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
        {
          headers: {
            Authorization: 'Basic ' + btoa(`${context.config?.CLIENT_ID}:${context.config?.CLIENT_SECRET}`),
          },
        }
      )
        .json<AccessTokenResponse>()
        .then((response) => {
          // Set the access tokens
          if (response.access_token) {
            dispatchContext({ type: IActionType.SET_ACCESSTOKEN, payload: response.access_token });
          }

          // Update or remove the refresh token
          if (response.refresh_token) {
            dispatchContext({ type: IActionType.SET_REFRESHTOKEN, payload: response.refresh_token });
          } else {
            dispatchContext({ type: IActionType.REMOVE_REFRESHTOKEN });
          }

          dispatchContext({ type: IActionType.CLEAR_AUTH_ERROR_MESSAGE });

          dispatchContext({ type: IActionType.SET_AUTHENTICATED, payload: true });

          // Redirect to the dashboard
          history.push('/dashboard');
        })
        .catch((e) => {
          dispatchContext({ type: IActionType.SET_AUTHENTICATED, payload: false });
          dispatchContext({ type: IActionType.REMOVE_ACCESSTOKEN });
          dispatchContext({ type: IActionType.REMOVE_REFRESHTOKEN });

          // Check the response code for an error message
          let error = '';
          if ((e.response?.status as number) >= 400 && (e.response?.status as number) < 500) {
            error = 'Either your username or your password is incorrect';
          } else if ((e.response?.status as number) >= 500 && (e.response?.status as number) < 600) {
            error = 'The server is not able to process your request';
          }

          dispatchContext({ type: IActionType.SET_AUTH_ERROR_MESSAGE, payload: error });
        })
        .finally(() => {
          setProcessRunning(false);
        });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Mindmotiv Authentication Server
        </Typography>
        {!!context.authenticationErrorMessage && (
          <Alert className={classes.error} severity="error">
            {context.authenticationErrorMessage ?? ''}
          </Alert>
        )}
        {context.authenticated ? (
          <div style={{ marginTop: '1em' }}>You're already logged in.</div>
        ) : (
          <>
            <TextField
              variant="outlined"
              color="primary"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
              disabled={processRunning || context.authenticated}
            />
            <TextField
              variant="outlined"
              color="primary"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              disabled={processRunning || context.authenticated}
            />
          </>
        )}
        <ProgressButton
          showProgress={processRunning}
          variant="contained"
          color="primary"
          fullWidth
          className={classes.loginButton}
          onClick={() => handleLoginLogout()}
        >
          {context.authenticated ? 'Logout' : 'Login'}
        </ProgressButton>
      </Paper>
    </Container>
  );
};

export default Login;
