import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ContextStore from './context/ContextStore';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.css';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ContextStore>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ContextStore>
  </React.StrictMode>,
  document.getElementById('root')
);
