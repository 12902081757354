import { Button, Checkbox, Chip, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import AuthorityDto from '../model/DTOs/AuthorityDto';
import ClientDto, { GrantTypes } from '../model/DTOs/ClientDto';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useQuery } from 'react-query';
import ResourceDto from '../model/DTOs/ResourceDto';

const useStyles = makeStyles(() =>
  createStyles({
    tokenValidityContainer: {
      marginTop: '0.5em',
    },
    tokenValidtyContainerLeft: {
      marginRight: '0.5em',
      flex: 1,
    },
    tokenValidtyContainerRight: {
      marginLeft: '0.5em',
      flex: 1,
    },
    tokenValidityTextField: {
      width: '100%',
    },
    chip: {
      margin: '0.5em',
    },
    divider: {
      margin: '1em',
    },
  })
);

export interface IAddEditClientDialogProps {
  existingClient?: ClientDto;
  open: boolean;
  onClose: () => void;
}

const AddEditClientDialog: React.FunctionComponent<IAddEditClientDialogProps> = (props) => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  const { existingClient, open, onClose } = props;

  const defaultClient = useMemo<ClientDto>(() => {
    return {
      authorizedGrantTypes: [GrantTypes.RefreshToken],
      accessTokenValiditySeconds: 1800, //10m
      refreshTokenValiditySeconds: 604800, //1w
    }
  }, []);

  const [tmpClient, setTmpClient] = useState<ClientDto>(defaultClient);

  // User experience
  const [clientIdTouched, setClientIdTouched] = useState(false);
  const [clientSecretTouched, setClientSecretTouched] = useState(false);
  const [grantTypesTouched, setGrantTypesTouched] = useState(false);
  const [accessTokenValiditySecondsTouched, setAccessTokenValiditySecondsTouched] = useState(false);
  const [refreshTokenValiditySecondsTouched, setRefreshTokenValiditySecondsTouched] = useState(false);

  // Errors
  const clientIdError = clientIdTouched && (tmpClient.clientId !== undefined && tmpClient.clientId.length === 0);
  const clientSecretError = clientSecretTouched && (tmpClient.clientSecret !== undefined && tmpClient.clientSecret.length === 0);
  const grantTypesError = grantTypesTouched && (tmpClient.authorizedGrantTypes !== undefined && tmpClient.authorizedGrantTypes.filter((grantType) => grantType !== GrantTypes.RefreshToken).length === 0);
  const accessTokenValiditySecondsError = accessTokenValiditySecondsTouched && (tmpClient.accessTokenValiditySeconds !== undefined && tmpClient.accessTokenValiditySeconds <= 0);
  const refreshTokenValiditySecondsError = refreshTokenValiditySecondsTouched && (tmpClient.refreshTokenValiditySeconds !== undefined && tmpClient.refreshTokenValiditySeconds <= 0);

  // Fetch the available resources
  const availableResources = useQuery(['resources'], () =>
    createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/resources`).json<ResourceDto[]>()
  );

  // Fetch the available authorities
  const availableAuthorities = useQuery(['authorities'], () =>
    createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/authorities`).json<AuthorityDto[]>()
  );

  useEffect(() => {
    if (open) {
      if (existingClient) {
        setTmpClient(existingClient);
      }
    } else {
      // Reset user experience when the dialog is closed
      setClientIdTouched(false);
      setClientSecretTouched(false);
      setGrantTypesTouched(false);
      setAccessTokenValiditySecondsTouched(false);
      setRefreshTokenValiditySecondsTouched(false);
    }

    return () => {
      setTmpClient(defaultClient);
    }
  }, [defaultClient, existingClient, open]);

  const handleClose = () => {
    onClose();
  };

  const handleGrantTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGrantTypesTouched(true);

    // Sanitize: If no grant-type has been set yet, initialize with an empty array
    if (tmpClient.authorizedGrantTypes === undefined) {
      tmpClient.authorizedGrantTypes = [];
    }

    let index = tmpClient.authorizedGrantTypes.indexOf(event.target.name as GrantTypes);
    let newGrantTypes = [...tmpClient.authorizedGrantTypes];

    if (event.target.checked) {
      if (index === -1) {
        newGrantTypes.push(event.target.name as GrantTypes);
      } else {
        newGrantTypes.splice(index, 1);
      }
    } else {
      if (index !== -1) {
        newGrantTypes.splice(index, 1);
      }
    }

    setTmpClient((old) => { return { ...old, authorizedGrantTypes: newGrantTypes } });
  };

  const handleAddOrEdit = () => {
    if (!existingClient) {
      createApiClient(context, dispatchContext)
        .post(`${context.config?.MAS_URL}/api/v1/clients`, {
          json: tmpClient,
        })
        .then(handleClose)
        .catch(() => { });
    } else {
      createApiClient(context, dispatchContext)
        .patch(`${context.config?.MAS_URL}/api/v1/clients/${existingClient.uuid}`, {
          json: tmpClient,
          searchParams: { replace: true }
        })
        .then(handleClose)
        .catch(() => { });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{existingClient ? 'Edit' : 'Add'} Client</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Client Information</Typography>
        <TextField
          margin="dense"
          id="id"
          label="Client ID"
          type="id"
          fullWidth
          value={tmpClient.clientId}
          onChange={(event) => {
            setClientIdTouched(true);
            setTmpClient((old) => { return { ...old, clientId: event.target.value } });
          }}
          error={clientIdError}
          required
        />
        <TextField
          margin="dense"
          id="secret"
          label="Client Secret"
          type="password"
          autoComplete="new-password"
          fullWidth
          value={tmpClient.clientSecret}
          onChange={(event) => {
            console.log("Event: " + event.target.value);
            console.log("State: " + tmpClient.clientSecret)
            setClientSecretTouched(true);
            setTmpClient((old) => { return { ...old, clientSecret: event.target.value } });
          }}
          error={clientSecretError}
          required
        />
        <div className={classes.divider} />
        <Typography variant="h6">Token Settings</Typography>
        <div className={classes.divider} />
        <FormControl required error={grantTypesError}>
          <FormLabel component="legend">Authorized Grant Type</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={tmpClient.authorizedGrantTypes?.indexOf(GrantTypes.Password) !== -1 || false}
                  onChange={handleGrantTypeChange}
                  name={GrantTypes.Password}
                />
              }
              label="Password"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={tmpClient.authorizedGrantTypes?.indexOf(GrantTypes.ClientCredentials) !== -1 || false}
                  onChange={handleGrantTypeChange}
                  name={GrantTypes.ClientCredentials}
                />
              }
              label="Client Credentials"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={tmpClient.authorizedGrantTypes?.indexOf(GrantTypes.AuthorizationCode) !== -1 || false}
                  onChange={handleGrantTypeChange}
                  name={GrantTypes.AuthorizationCode}
                  disabled
                />
              }
              label="Authorization Code"
            />
          </FormGroup>
          {grantTypesError && <FormHelperText>Select at least one</FormHelperText>}
        </FormControl>
        <div className={classes.divider} />
        <FormControl>
          <FormLabel component="legend">Additional Settings</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={tmpClient.authorizedGrantTypes?.indexOf(GrantTypes.RefreshToken) !== -1 || false}
                onChange={handleGrantTypeChange}
                name={GrantTypes.RefreshToken}
              />
            }
            label="Allow Refresh Token"
          />
        </FormControl>
        <Grid container className={classes.tokenValidityContainer} alignItems="center" justify="center">
          <Grid item className={classes.tokenValidtyContainerLeft}>
            <TextField
              id="accessTokenValiditySeconds"
              className={classes.tokenValidityTextField}
              color="primary"
              label="Access Token Validity Seconds"
              type="number"
              value={tmpClient.accessTokenValiditySeconds}
              onChange={(event) => {
                setAccessTokenValiditySecondsTouched(true);
                setTmpClient((old) => { return { ...old, accessTokenValiditySeconds: Number(event.target.value) } });
              }}
              error={accessTokenValiditySecondsError}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item className={classes.tokenValidtyContainerRight}>
            <TextField
              id="refreshTokenValiditySeconds"
              className={classes.tokenValidityTextField}
              color="primary"
              label="Refresh Token Validity Seconds"
              type="number"
              value={tmpClient.refreshTokenValiditySeconds}
              onChange={(event) => {
                setRefreshTokenValiditySecondsTouched(true);
                setTmpClient((old) => { return { ...old, refreshTokenValiditySeconds: Number(event.target.value) } });
              }}
              disabled={tmpClient.authorizedGrantTypes === undefined || tmpClient.authorizedGrantTypes.indexOf(GrantTypes.RefreshToken) === -1}
              error={refreshTokenValiditySecondsError}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <Typography variant="h6">Access Control</Typography>
        <div className={classes.divider} />
        <Autocomplete
          fullWidth
          multiple
          id="combo-box-authorities"
          options={availableAuthorities.data?.map((a) => a.name).sort() || []}
          value={tmpClient.authorities !== undefined ? tmpClient.authorities.map((a) => a.name).sort() : []}
          renderInput={(params) => <TextField {...params} label="Client Authorities" variant="outlined" size="small" placeholder="Authorities" />}
          renderOption={(option, { inputValue }) => {
            const matches = match(option, inputValue);
            const parts = parse(option, matches);

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip className={classes.chip} color="primary" label={option} size="small" {...getTagProps({ index })} />)
          }
          onChange={(_, value) => setTmpClient((old) => { return { ...old, authorities: availableAuthorities.data?.filter((authority) => value.indexOf(authority.name) !== -1) } })}
        />
        <div className={classes.divider} />
        <Autocomplete
          fullWidth
          multiple
          id="combo-box-resources"
          options={availableResources.data?.map((a) => a.name).sort() || []}
          value={tmpClient.resources !== undefined ? tmpClient.resources.map((a) => a.name).sort() : []}
          renderInput={(params) => <TextField {...params} label="Assigned Resources" variant="outlined" size="small" placeholder="Resources" />}
          renderOption={(option, { inputValue }) => {
            const matches = match(option, inputValue);
            const parts = parse(option, matches);

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip className={classes.chip} color="primary" label={option} size="small" {...getTagProps({ index })} />)
          }
          onChange={(_, value) => setTmpClient((old) => { return { ...old, resources: availableResources.data?.filter((resource) => value.indexOf(resource.name) !== -1) } })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleAddOrEdit}>
          {existingClient ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditClientDialog;
