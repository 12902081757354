import { Box, Link, Typography } from '@material-ui/core';

const Copyright: React.FunctionComponent = () => {
  return (
    <Box mt={8}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'© '}
        <Link color="inherit" href="https://mindmotiv.com/">
          Mindmotiv GmbH
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
};

export default Copyright;
