import { Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import PrivilegeDto from '../model/DTOs/PrivilegeDto';
import UserDto, { UserRole } from '../model/DTOs/UserDto';
import Utils from '../utils/Utils';
import PrivilegeTransferList from '../components/PrivilegeTransferList';
import { useQuery } from 'react-query';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      width: '100%',
    },
    divider: {
      margin: '1em',
    },
    roleRadioGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
    privilegeList: {
      marginTop: '1em',
    },
  })
);

export interface IAddEditUserDialogProps {
  existingUser?: UserDto;
  open: boolean;
  onClose: () => void;
}

const AddEditUserDialog: React.FunctionComponent<IAddEditUserDialogProps> = (props) => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  const { existingUser, open, onClose } = props;

  const defaultUser = useMemo<UserDto>(() => { return { roles: [UserRole.User] } }, []);
  const [tmpUser, setTmpUser] = useState<UserDto>(defaultUser);

  const [confirmPassword, setConfirmPassword] = useState<string>();

  // User experience
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [rolesTouched, setRolesTouched] = useState(false);

  // Errors
  const usernameError = usernameTouched && tmpUser.username?.length === 0;
  const passwordError = passwordTouched && confirmPasswordTouched && (tmpUser.password?.length === 0 || tmpUser.password !== confirmPassword);
  const confirmPasswordError = confirmPasswordTouched && passwordTouched && (confirmPassword?.length === 0 || tmpUser.password !== confirmPassword);
  const emailError = emailTouched && !tmpUser.email?.match(Utils.EMAIL_REGEX);
  const roleError = rolesTouched && tmpUser.roles?.length === 0;

  // Clears the inputs when opening the dialog
  useEffect(() => {
    if (open) {
      if (existingUser) {
        setTmpUser(existingUser);
      }
    } else {
      // Reset user experience when the dialog is closed
      setUsernameTouched(false);
      setPasswordTouched(false);
      setConfirmPasswordTouched(false);
      setEmailTouched(false);
      setRolesTouched(false);
    }

    return () => {
      setTmpUser(defaultUser);
    }
  }, [defaultUser, existingUser, open]);

  const availablePrivileges = useQuery(['privileges'], () =>
    createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/privileges`, { searchParams: { size: 2000 } }).json<PrivilegeDto[]>()
  );

  const handleClose = () => {
    onClose();
  };

  const handleRoleToggle = (role: UserRole) => {
    setRolesTouched(true);

    const index = tmpUser.roles?.indexOf(role) ?? -1;
    const newRoles = [...tmpUser.roles ?? []];
    if (index === -1) {
      newRoles.push(role);
    } else {
      newRoles.splice(index, 1);
    }

    setTmpUser((old) => { return { ...old, roles: newRoles }; });
  };

  const handleAddOrEdit = () => {
    if (!existingUser) {
      createApiClient(context, dispatchContext)
        .post(`${context.config?.MAS_URL}/api/v1/users`, {
          json: tmpUser,
        })
        .then(handleClose)
        .catch(() => { });
    } else {
      createApiClient(context, dispatchContext)
        .patch(`${context.config?.MAS_URL}/api/v1/users/${existingUser.uuid}`, {
          json: tmpUser,
          searchParams: { replace: true }
        })
        .then(handleClose)
        .catch(() => { });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{existingUser ? 'Edit' : 'Add'} User</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Personal Information</Typography>
        <TextField
          margin="dense"
          id="name"
          label="Username"
          type="name"
          fullWidth
          value={tmpUser.username}
          onChange={(event) => {
            setUsernameTouched(true);
            setTmpUser((old) => { return { ...old, username: event.target.value }; });
          }}
          error={usernameError}
          required
        />
        <TextField
          margin="dense"
          id="password"
          label="New Password"
          type="password"
          autoComplete="new-password"
          fullWidth
          value={tmpUser.password}
          onChange={(event) => {
            setPasswordTouched(true);
            setTmpUser((old) => { return { ...old, password: event.target.value }; });
          }}
          error={passwordError}
          required
        />
        <TextField
          margin="dense"
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          autoComplete="new-password"
          fullWidth
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPasswordTouched(true);
            setConfirmPassword(event.target.value);
          }}
          error={confirmPasswordError}
          required
        />
        <TextField
          margin="dense"
          id="firstname"
          label="Firstname"
          type="firstname"
          fullWidth
          value={tmpUser.firstname}
          onChange={(event) => {
            setTmpUser((old) => { return { ...old, firstname: event.target.value }; });
          }}
        />
        <TextField
          margin="dense"
          id="lastname"
          label="Lastname"
          type="lastname"
          fullWidth
          value={tmpUser.lastname}
          onChange={(event) => {
            setTmpUser((old) => { return { ...old, lastname: event.target.value }; });
          }}
        />
        <TextField
          margin="dense"
          id="email"
          label="E-Mail"
          type="email"
          fullWidth
          value={tmpUser.email}
          onChange={(event) => {
            setEmailTouched(true);
            setTmpUser((old) => { return { ...old, email: event.target.value }; });
          }}
          error={emailError}
          required
        />
        <div className={classes.divider} />
        <Typography variant="h6">Access Control</Typography>
        <div className={classes.divider} />
        <FormControl className={classes.form} component="fieldset" required error={roleError}>
          <FormLabel component="legend">Role</FormLabel>
          {/* prettier-ignore */}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={tmpUser.roles?.indexOf(UserRole.User) !== -1 || false}
                  onChange={() => handleRoleToggle(UserRole.User)}
                />
              }
              label="User"
            />
            <FormControlLabel
              control={
                <Checkbox color="primary"
                  checked={tmpUser.roles?.indexOf(UserRole.Supervisor) !== -1 || false}
                  onChange={() => handleRoleToggle(UserRole.Supervisor)}
                />
              }
              label="Supervisor"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={tmpUser.roles?.indexOf(UserRole.Administrator) !== -1 || false}
                  onChange={() => handleRoleToggle(UserRole.Administrator)}
                />
              }
              label="Administrator"
            />
          </FormGroup>
          <FormHelperText>Please select at least one</FormHelperText>
        </FormControl>
        <div className={classes.divider} />
        <FormControl className={classes.form} component="fieldset" required>
          <FormLabel component="legend">Privileges</FormLabel>
          <div className={classes.privilegeList}>
            <PrivilegeTransferList privileges={availablePrivileges.data || []} assignedPrivileges={tmpUser.privileges || []} onPrivilegeUpdate={(newPrivileges) => setTmpUser((old) => { return { ...old, privileges: newPrivileges } })} />
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleAddOrEdit}>
          {existingUser ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditUserDialog;
