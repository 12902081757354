import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '1em',
      marginBottom: '1em',
    },
  })
);

const Loading: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default Loading;
