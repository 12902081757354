import { makeStyles, createStyles, TableRow, TableCell, Grid, InputBase, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import PrivilegeDto from '../model/DTOs/PrivilegeDto';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ResourceDto from '../model/DTOs/ResourceDto';

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      boxSizing: 'border-box',
      borderLeft: '5px solid transparent',
      transition: 'border-left 0.1s ease-in-out',
    },
    selectedRow: {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
    inputGridItem: {
      flexGrow: 1,
    },
    inputField: {
      width: '100%',
    },
    error: {
      color: theme.palette.error.main as string,
    },
  })
);

interface IManageResourceRowProps {
  resource: ResourceDto;
  onUpdate?: (name: string, uuid: string) => boolean;
  onEditButton?: () => void;
  onDoneButton?: () => void;
  removeFromTemporaryResources?: () => void;
}

const ManageResourceRow: React.FunctionComponent<IManageResourceRowProps> = (props) => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  let { resource, onEditButton, onUpdate, onDoneButton, removeFromTemporaryResources } = props;

  const [selected, setSelected] = useState(!resource.uuid);
  const [resourceName, setResourceName] = useState(resource.name);
  const [error, setError] = useState(false);

  const queryClient = useQueryClient();

  const addResource = useMutation(
    (newResource: ResourceDto) =>
      createApiClient(context, dispatchContext).post(`${context.config?.MAS_URL}/api/v1/resources`, {
        json: newResource,
      }),
    { onSuccess: () => queryClient.invalidateQueries('resources') }
  );

  const updateResource = useMutation(
    (resourceToUpdate: PrivilegeDto) =>
      createApiClient(context, dispatchContext).patch(`${context.config?.MAS_URL}/api/v1/resources/${resourceToUpdate.uuid}`, {
        json: resourceToUpdate,
      }),
    { onSuccess: () => queryClient.invalidateQueries('resources') }
  );

  const deleteResource = useMutation(
    (resourceId: string) => createApiClient(context, dispatchContext).delete(`${context.config?.MAS_URL}/api/v1/resources/${resourceId}`),
    { onSuccess: () => queryClient.invalidateQueries('resources') }
  );

  const handleSave = () => {
    if (error) {
      return;
    }

    if (resource.uuid) {
      updateResource.mutate({ ...resource, name: resourceName });
    } else {
      addResource.mutate({ ...resource, name: resourceName });
      removeFromTemporaryResources?.();
    }
    setSelected(false);
    onDoneButton?.();
  };

  return (
    <TableRow key={resource.uuid}>
      <TableCell className={clsx(selected && classes.selectedRow, classes.row)} align="left">
        <Grid container alignItems="center" justify="space-between">
          <Grid item className={classes.inputGridItem}>
            <InputBase
              className={clsx(error && classes.error, classes.inputField)}
              value={resourceName}
              readOnly={!selected}
              onChange={(event) => {
                let name = event.target.value as string;
                setResourceName(name);
                setError(!(onUpdate?.(name, resource.uuid || resource.localUuid) ?? true));
              }}
              onKeyPress={(event) => {
                if (event.code === 'Enter') {
                  handleSave();
                }
              }}
              inputRef={(input) => input && selected && input.focus()}
            />
          </Grid>
          <Grid item>
            {!selected ? (
              <IconButton
                size="small"
                onClick={() => {
                  setSelected(true);
                  onEditButton?.();
                }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={handleSave} disabled={error}>
                <DoneIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">{resource.uuid || '<Will be generated automatically>'}</TableCell>
      <TableCell align="right">
        <IconButton
          size="small"
          onClick={() => {
            if (resource.uuid) {
              deleteResource.mutate(resource.uuid);
            }
            removeFromTemporaryResources?.();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ManageResourceRow;
