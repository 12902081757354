import PrivilegeDto from './PrivilegeDto';

export enum UserRole {
  User = 'ROLE_USER',
  Supervisor = 'ROLE_SUPERVISOR',
  Administrator = 'ROLE_ADMIN',
}

export default interface UserDto {
  uuid?: string;
  username?: string;
  password?: string;
  roles?: UserRole[];
  privileges?: PrivilegeDto[];
  enabled?: boolean;
  locked?: boolean;
  credentialsExpired?: boolean;
  expired?: boolean;
  title?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
}
