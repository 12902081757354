import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import useTriggerUpdate from '../hooks/useTriggerUpdate';
import { useQuery } from 'react-query';
import ManageResourceRow from '../components/ManageResourceRow';
import ResourceDto from '../model/DTOs/ResourceDto';
import Utils from "../utils/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    row: {
      borderLeft: '5px solid transparent',
      transition: 'border-left 0.1s ease-in-out',
    },
    selectedRow: {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
  })
);

interface IManageResourcesDialogProps {
  open: boolean;
  onClose: () => void;
}

const ManageResourcesDialog: React.FunctionComponent<IManageResourcesDialogProps> = (props) => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  const { open, onClose } = props;

  const [editingResource, setEditingResource] = useState(false);

  const [localAvailableResources, setLocalAvailableResources] = useState<ResourceDto[]>([]);
  const [temporaryResources, setTemporaryResources] = useState<ResourceDto[]>([]);

  const [, triggerComponentUpdate] = useTriggerUpdate();

  // Fetch the available resources
  const availableResources = useQuery(
    ['resources'],
    () => createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/resources`).json<ResourceDto[]>(),
    {
      onSuccess: (data) => setLocalAvailableResources(data),
    }
  );

  // Reset the dialog on open
  useEffect(() => {
    setEditingResource(false);
    setTemporaryResources([]);
  }, [open]);

  // Updates the local resource information
  const onResourceUpdate = (name: string, uuid: string) => {
    setTemporaryResources((oldResources) => {
      oldResources.forEach((resource) => {
        if (resource.localUuid === uuid) {
          resource.name = name;
        }
      });

      return oldResources;
    });

    setLocalAvailableResources((oldResources) => {
      oldResources.forEach((resource) => {
        if (resource.uuid === uuid) {
          resource.name = name;
        }
      });

      return oldResources;
    });

    return [...localAvailableResources, ...temporaryResources].find((t) => t.name === name && (t.uuid || t.localUuid) !== uuid) === undefined;
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Resources</DialogTitle>
      <DialogContent>
        <Grid container justify="flex-end">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={!availableResources.isSuccess || editingResource}
            onClick={() => {
              // Set the editing state to avoid multiple additions at once
              setEditingResource(true);

              let tmpResources = temporaryResources;
              tmpResources.push(new ResourceDto());
              setTemporaryResources(tmpResources);

              // Trigger UI update
              triggerComponentUpdate();
            }}
          >
            Add Resource
          </Button>
        </Grid>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">UUID</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {localAvailableResources?.sort((a, b) => Utils.sortAlphabetically(a.name, b.name)).map((resource, index) => (
                <ManageResourceRow
                  key={resource.uuid || resource.localUuid}
                  resource={resource}
                  onUpdate={onResourceUpdate}
                  onEditButton={() => setEditingResource(true)}
                  onDoneButton={() => setEditingResource(false)}
                />
              ))}
              {temporaryResources.sort((a, b) => Utils.sortAlphabetically(a.name, b.name)).map((resource, index) => (
                <ManageResourceRow
                  key={resource.uuid || resource.localUuid}
                  resource={resource}
                  onUpdate={onResourceUpdate}
                  onEditButton={() => setEditingResource(true)}
                  onDoneButton={() => setEditingResource(false)}
                  removeFromTemporaryResources={() => {
                    setTemporaryResources((oldResources) => {
                      oldResources.splice(index, 1);
                      return oldResources;
                    });
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageResourcesDialog;
