import ky, { HTTPError } from 'ky';
import { Dispatch } from 'react';
import { IAction, IActionType, IContext } from '../context/ContextInterfaces';
import AccessTokenResponse from '../model/AccessTokenResponse';

export function createApiClient(context: IContext, dispatchContext: Dispatch<IAction>) {
  return ky.extend({
    hooks: {
      beforeRequest: [
        (_request, _options) => {
          _request.headers.set('Authorization', 'Bearer ' + context.accessToken);
        },
      ],
      afterResponse: [
        async (_request, _options, response) => {
          if (response.status === 401 || response.status === 403) {
            // Try to refresh the token if a refresh token has been stored
            if (context.refreshToken) {
              console.log('Attempting to refresh the access token');
              try {
                const tokens = await ky
                  .post(`${context.config?.MAS_URL}/oauth/token?grant_type=refresh_token&refresh_token=${context.refreshToken}`, {
                    headers: {
                      Authorization: 'Basic ' + btoa(`${context.config?.CLIENT_ID}:${context.config?.CLIENT_SECRET}`),
                    },
                  })
                  .json<AccessTokenResponse>()
                  .then((response) => {
                    return Promise.resolve({
                      access_token: response.access_token,
                      refresh_token: response.refresh_token,
                    });
                  });

                // Store the access token
                dispatchContext({ type: IActionType.SET_ACCESSTOKEN, payload: tokens.access_token });

                // Catch the case that no refresh token has been provided
                if (tokens.refresh_token) {
                  dispatchContext({ type: IActionType.SET_REFRESHTOKEN, payload: tokens.refresh_token });
                } else {
                  dispatchContext({ type: IActionType.REMOVE_REFRESHTOKEN });
                }

                console.log('Refreshed tokens successfully');

                // Update the request and retry it
                _request.headers.set('Authorization', 'Bearer ' + tokens.access_token);
                return ky(_request);
              } catch (e) {
                console.error('Could not refresh tokens');
              }
            } else {
              console.error('No refresh token available');
            }

            // Set an appropriate error message for the login screen
            if (response.status === 401) {
              dispatchContext({ type: IActionType.SET_AUTH_ERROR_MESSAGE, payload: 'Session expired, please log in again' });
            }

            if (response.status === 403) {
              dispatchContext({ type: IActionType.SET_AUTH_ERROR_MESSAGE, payload: "You haven't enough permissions to access this service" });
            }

            // Remove the (possibly) stored tokens
            dispatchContext({ type: IActionType.REMOVE_ACCESSTOKEN });
            dispatchContext({ type: IActionType.REMOVE_REFRESHTOKEN });

            // Set the authentication flag to false which would lead the user to the login screen again
            dispatchContext({ type: IActionType.SET_AUTHENTICATED, payload: false });

            // Also throw the error to the component which tried to fetch
            throw new HTTPError(response, _request, _options);
          }
        },
      ],
    },
  });
}
