import { createStyles, CssBaseline, makeStyles, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { theme } from '../theme/Theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      height: '100vh',
      boxSizing: 'border-box',
    },
  })
);

const AppContainer: React.FunctionComponent = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.appContainer}>
      <CssBaseline />
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </div>
  );
};

export default AppContainer;
