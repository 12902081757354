import AuthorityDto from './AuthorityDto';
import ResourceDto from './ResourceDto';

export enum GrantTypes {
  Password = 'password',
  RefreshToken = 'refresh_token',
  ClientCredentials = 'client_credentials',
  AuthorizationCode = 'authorization_code',
}

export default interface ClientDto {
  uuid?: string;
  clientId?: string;
  clientSecret?: string;
  resources?: ResourceDto[];
  scope?: string[];
  authorizedGrantTypes?: GrantTypes[];
  redirectUri?: string[];
  authorities?: AuthorityDto[];
  accessTokenValiditySeconds?: number;
  refreshTokenValiditySeconds?: number;
  autoApprove?: boolean;
}
