import React, { createContext, Dispatch, useEffect, useReducer } from 'react';
import { IAction, IActionType, IContext } from './ContextInterfaces';
import ContextReducer from './ContextReducer';

const initialContext: IContext = {
  config: undefined,
  authenticated: (localStorage.getItem('accessToken') || '') !== '',
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
};

export const Context = createContext<[IContext, Dispatch<IAction>]>([initialContext, () => { }]);

const ContextStore: React.FunctionComponent = (props) => {
  const [context, dispatchContext] = useReducer(ContextReducer, initialContext);

  useEffect(() => {
    // Avoid caching of the config.json
    const headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');

    // Request the config.json
    fetch("/config/config.json", { headers: headers })
      .then(response => response.json())
      .then(data => dispatchContext({ type: IActionType.SET_CONFIG, payload: data }))
      .catch(reason => console.error(reason));
  }, [])

  return <Context.Provider value={[context, dispatchContext]}>{props.children}</Context.Provider>;
};

export default ContextStore;
