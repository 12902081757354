import React, { useContext } from 'react';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Copyright from './components/Copyright';
import AppContainer from './components/AppContainer';
import { Context } from './context/ContextStore';
import { Box, Typography } from '@material-ui/core';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

const App: React.FunctionComponent = () => {
  const [context] = useContext(Context);

  return (
    <AppContainer>
      {/* Only render the app when a config file could have been loaded */}
      {context.config ? (
        <BrowserRouter>
          {/* Route that is always accessible */}
          <Route path="/login">
            <Login />
          </Route>
          {/* Only accessible if authenticated */}
          {context.authenticated ? (
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
              <Route path="/dashboard">
                <Dashboard />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route path="/login" />
              <Redirect to="/login" />
            </Switch>
          )}
        </BrowserRouter>
      ) : (
        <Box width='100%' height='100%' display='flex' justifyContent='center'>
          <Box display='flex' flexDirection='column' justifyContent='center'>
            <Typography variant='h6'>
              No config has been found. The frontend seems to be broken. Please contact your system administrator.
            </Typography>
          </Box>
        </Box>
      )}
      <Copyright />
    </AppContainer>
  );
};

export default App;
