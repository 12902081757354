import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { createApiClient } from '../api/ApiClient';
import { Context } from '../context/ContextStore';
import useTriggerUpdate from '../hooks/useTriggerUpdate';
import { useQuery } from 'react-query';
import ManageAuthorityRow from '../components/ManageAuthorityRow';
import AuthorityDto from '../model/DTOs/AuthorityDto';
import Utils from "../utils/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    row: {
      borderLeft: '5px solid transparent',
      transition: 'border-left 0.1s ease-in-out',
    },
    selectedRow: {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
  })
);

export interface IManageAuthoritiesDialogProps {
  open: boolean;
  onClose: () => void;
}

const ManageAuthoritiesDialog: React.FunctionComponent<IManageAuthoritiesDialogProps> = (props) => {
  const classes = useStyles();
  const [context, dispatchContext] = useContext(Context);

  const { open, onClose } = props;

  const [editingAuthority, setEditingAuthority] = useState(false);

  const [localAvailableAuthorities, setLocalAvailableAuthorities] = useState<AuthorityDto[]>([]);
  const [temporaryAuthorities, setTemporaryAuthorities] = useState<AuthorityDto[]>([]);

  const [, triggerComponentUpdate] = useTriggerUpdate();

  // Fetch the available authorities
  const availableAuthorities = useQuery(
    ['authorities'],
    () => createApiClient(context, dispatchContext).get(`${context.config?.MAS_URL}/api/v1/authorities`).json<AuthorityDto[]>(),
    {
      onSuccess: (data) => setLocalAvailableAuthorities(data),
    }
  );

  // Reset the dialog on open
  useEffect(() => {
    setEditingAuthority(false);
    setTemporaryAuthorities([]);
  }, [open]);

  // Updates the local authority information
  const onAuthorityUpdate = (name: string, uuid: string) => {
    setTemporaryAuthorities((oldAuthorities) => {
      oldAuthorities.forEach((authority) => {
        if (authority.localUuid === uuid) {
          authority.name = name;
        }
      });

      return oldAuthorities;
    });

    setLocalAvailableAuthorities((oldAuthorities) => {
      oldAuthorities.forEach((authority) => {
        if (authority.uuid === uuid) {
          authority.name = name;
        }
      });

      return oldAuthorities;
    });

    return [...localAvailableAuthorities, ...temporaryAuthorities].find((t) => t.name === name && (t.uuid || t.localUuid) !== uuid) === undefined;
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Authorities</DialogTitle>
      <DialogContent>
        <Grid container justify="flex-end">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={!availableAuthorities.isSuccess || editingAuthority}
            onClick={() => {
              // Set the editing state to avoid multiple additions at once
              setEditingAuthority(true);

              let tmpAuths = temporaryAuthorities;
              tmpAuths.push(new AuthorityDto());
              setTemporaryAuthorities(tmpAuths);

              // Trigger UI update
              triggerComponentUpdate();
            }}
          >
            Add Authority
          </Button>
        </Grid>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">UUID</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {localAvailableAuthorities?.sort((a, b) => Utils.sortAlphabetically(a.name, b.name)).map((authority, index) => (
                <ManageAuthorityRow
                  key={authority.uuid || authority.localUuid}
                  authority={authority}
                  onUpdate={onAuthorityUpdate}
                  onEditButton={() => setEditingAuthority(true)}
                  onDoneButton={() => setEditingAuthority(false)}
                />
              ))}
              {temporaryAuthorities.sort((a, b) => Utils.sortAlphabetically(a.name, b.name)).map((authority, index) => (
                <ManageAuthorityRow
                  key={authority.uuid || authority.localUuid}
                  authority={authority}
                  onUpdate={onAuthorityUpdate}
                  onEditButton={() => setEditingAuthority(true)}
                  onDoneButton={() => setEditingAuthority(false)}
                  removeFromTemporaryAuthorities={() => {
                    setTemporaryAuthorities((oldAuthorities) => {
                      oldAuthorities.splice(index, 1);
                      return oldAuthorities;
                    });
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageAuthoritiesDialog;
