import { Button, Checkbox, createStyles, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PrivilegeDto from '../model/DTOs/PrivilegeDto';
import Utils from "../utils/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    list: {
      flex: '1',
    },
    buttons: {
      margin: '1em',
    },
    paper: {
      height: '200px',
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

function intersection(a: PrivilegeDto[], b: PrivilegeDto[]) {
  return a.filter((privA) => b.map((privB) => privB.uuid).indexOf(privA.uuid) !== -1);
}

function not(a: PrivilegeDto[], b: PrivilegeDto[]) {
  return a.filter((privA) => b.map((privB) => privB.uuid).indexOf(privA.uuid) === -1);
}

export interface IPrivilegeTransferListProps {
  privileges: PrivilegeDto[];
  assignedPrivileges: PrivilegeDto[];
  onPrivilegeUpdate: (privileges: PrivilegeDto[]) => void;
}

const PrivilegeTransferList: React.FunctionComponent<IPrivilegeTransferListProps> = (props) => {
  const classes = useStyles();

  const { privileges, assignedPrivileges, onPrivilegeUpdate } = props;

  const [unassigned, setUnassigned] = useState<PrivilegeDto[]>(not(privileges, assignedPrivileges));
  const [assigned, setAssigned] = useState<PrivilegeDto[]>(assignedPrivileges);

  const [checked, setChecked] = useState<PrivilegeDto[]>([]);
  const unassignedChecked = intersection(checked, unassigned);
  const assignedChecked = intersection(checked, assigned);

  useEffect(() => {
    setUnassigned(not(privileges, assignedPrivileges));
    setAssigned(assignedPrivileges);
  }, [privileges, assignedPrivileges]);

  const handleToggle = (privilege: PrivilegeDto) => {
    const currentIndex = checked.indexOf(privilege);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(privilege);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAssign = () => {
    const newAssigned = assigned.concat(unassignedChecked);
    const newUnassigned = not(unassigned, unassignedChecked);
    const newChecked = not(checked, unassignedChecked);

    onPrivilegeUpdate(newAssigned);

    setAssigned(newAssigned);
    setUnassigned(newUnassigned);
    setChecked(newChecked);
  };

  const handleUnassign = () => {
    const newUnassigned = unassigned.concat(assignedChecked);
    const newAssigned = not(assigned, assignedChecked);
    const newChecked = not(assigned, assignedChecked);

    onPrivilegeUpdate(newAssigned);

    setUnassigned(newUnassigned);
    setAssigned(newAssigned);
    setChecked(newChecked);
  };

  const privilegeList = (privileges: PrivilegeDto[]) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {privileges.sort((a, b) => Utils.sortAlphabetically(a.name, b.name)).map((privilege) => (
          <ListItem
            dense
            key={privilege.uuid}
            role="listitem"
            button
            onClick={() => {
              handleToggle(privilege);
            }}
          >
            <ListItemIcon>
              <Checkbox
                onClick={() => {
                  handleToggle(privilege);
                }}
                checked={checked.indexOf(privilege) !== -1}
                tabIndex={-1}
                color="primary"
                disableRipple
              />
            </ListItemIcon>
            <ListItemText id={`transfer-list-item-${privilege.uuid}-label`} primary={privilege.name} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );

  return (
    <Grid container className={classes.container} alignItems="center" justify="center">
      <Grid item className={classes.list}>
        <Grid container direction="column">
          <Grid item>Available Privileges</Grid>
          <Grid item>{privilegeList(unassigned)}</Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.buttons}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAssign}
            disabled={unassignedChecked.length === 0}
            aria-label="move selected right"
          >
            &gt; {/** > */}
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleUnassign}
            disabled={assignedChecked.length === 0}
            aria-label="move selected left"
          >
            &lt; {/** < */}
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.list}>
        <Grid container direction="column">
          <Grid item>Assigned Privileges</Grid>
          <Grid item>{privilegeList(assigned)}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivilegeTransferList;
